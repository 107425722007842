import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import banner from "../assets/images/banner-img.png";
import service1 from "../assets/images/service1.png";
import arrow from "../assets/images/arrow.png";
import leftarrow from "../assets/images/arrow.png";
import services2 from "../assets/images/services2.png";
import services3 from "../assets/images/services3.png";
import services4 from "../assets/images/service4.png";
import services5 from "../assets/images/service5.png";
import cancel from "../assets/images/cancel.png";
import keyboard from "../assets/images/keyboard.png";
import home from "../assets/images/home.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [fullData, setFulldata] = useState();
  const [iframeSrc, setIframeSrc] = useState('');
  const [isKeyboardVisible, setKeyboardVisibility] = useState(false);
  const [input, setInput] = useState('');
 
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];       //console.log(path, 'werty')
  let pathM = !pathMatch ? 'kiosk-1' : pathMatch;
 
  

  const getData = async () => {
    const response = await axios.get(
      `https://basscoastsscowes.com.au:4000/api/v1/website/websiteData?search=${pathM}`
    );
   console.log(response, 'df', pathM, 'erer')
    setFulldata(response?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  

  const serviceHandler = (ele) => {
    console.log("ele", ele)
    setIframeSrc(ele?.serviceUrl);  
  };

  
  function navigatehome(){
    navigate('/')
    setIframeSrc('')
  }

  const handleButtonClick = () => {
    setKeyboardVisibility(!isKeyboardVisible);
   // window.scrollTo(0,10000)
  };
  const onKeyPress = (button) => {
    setInput((prevInput) => prevInput + button);
  };
  return (
    <div>
      <section>


        <div class="banner">
          <div class="container">
            <div class="main-header">
              <img src={logo} alt="" />
            </div>

            <div class="banner-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="banner-left">
                    <h1>{fullData?.bannerData?.[0]?.bannerName ?? ""}</h1>


                    <p>
                      {fullData?.bannerData?.[0]?.description ?? ""}
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="banner-right">
            <figure>
              <img
                src={fullData?.bannerData?.[0]?.image ?? banner}
                alt=""
              />
            </figure>
          </div>
        </div>


      </section>
      <section>
        {
       iframeSrc? 
        <iframe className="services-area" sandbox="allow-scripts allow-popups allow-forms allow-same-origin"  src={iframeSrc} width="100%" height='500px' target="_self" title="Iframe Example"></iframe>:
        <div className="services">
          <div className="container">
            <h2>Service</h2>
            <div className="services-area">
              <div className="row">
                {fullData?.serviceData && fullData?.serviceData?.length > 0 ? (
                  <>{fullData?.serviceData?.map((ele) => {

                    return (
                      <div className="col-sm-4">
                        <div className="cart">
                          <figure>
                            <img
                              src={
                                ele.image
                              }
                              alt=""
                            />
                          </figure>
                          <aside>
                            <a role="button" onClick={() => serviceHandler(ele)} style={{"color": "blue"}}>
                              {ele?.serviceName}
                            </a>

                            {/* {iframeSrc && 
                            <iframe sandbox="allow-scripts allow-popups allow-forms allow-same-origin"  src={iframeSrc} width="100%" height='100%' target="_self" title="Iframe Example"></iframe>
                        
                            } */}

                            <p className="pergraph">
                              {/* You must register with local council all dogs and cats 3
                        months of age. Existing registrations must be renewed by
                        10 April each year. */}
                              {ele.description}
                            </p>
                          </aside>
                          <article>
                            <Link to='/services'
                              state={ele}

                            >
                              <img src={arrow} alt="" />
                            </Link>
                          </article>
                        </div>
                      </div>
                    );
                  })}</>
                ) : (
                  ""
                )}
                {/* <div className="col-sm-4">
                  <div className="cart">
                    <figure>
                      <img
                        src={
                          fullData?.serviceData?.[0]?.serviceImage ?? service1
                        }
                        alt=""
                      />
                    </figure>
                    <aside>
                      <h3>{fullData?.serviceData?.[0]?.serviceName}</h3>
                      <p>
                        
                        {fullData?.serviceData?.[0]?.description}
                      </p>
                    </aside>
                    <article>
                      <a
                        href={fullData?.serviceData?.[0]?.serviceUrl}
                        target="_blank"
                      >
                        <img src={arrow} alt="" />
                      </a>
                    </article>
                  </div>
                </div> */}
                {/* <div className="col-sm-4">
                  <div className="cart">
                    <figure>
                      <img src={services2} alt="" />
                    </figure>
                    <aside>
                      <h3>Animal management and emergencies</h3>
                      <p>
                        Resources and information sources for managing animal
                        welfare in an emergency. Domestic Animal Management
                        Plan.
                      </p>
                    </aside>
                    <article>
                      <a href="services.html">
                        <img src={arrow} alt="" />
                      </a>
                    </article>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="cart">
                    <figure>
                      <img src={services3} alt="" />
                    </figure>
                    <aside>
                      <h3>Responsible animal ownership</h3>
                      <p>
                        We help residents manage their animals to protect their
                        welfare, so they do not create a risk or nuisance to
                        other people and their animals.
                      </p>
                    </aside>
                    <article>
                      <a href="services.html">
                        <img src={arrow} alt="" />
                      </a>
                    </article>
                  </div>
                </div>
                <div className="col-sm-4 offset-sm-2">
                  <div className="cart">
                    <figure>
                      <img src={services4} alt="" />
                    </figure>
                    <aside>
                      <h3>Getting help with nuisance animals</h3>
                      <p>
                        How to make a complaint to Council about roaming dogs or
                        livestock, dog attacks, barking dogs or nuisance cats.
                        How to get help with wildlife, birds.
                      </p>
                    </aside>
                    <article>
                      <a href="services.html">
                        <img src={arrow} alt="" />
                      </a>
                    </article>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="cart">
                    <figure>
                      <img src={services5} alt="" />
                    </figure>
                    <aside>
                      <h3>Dangerous, menacing and restricted breed dogs</h3>
                      <p>
                        To protect our community, dangerous or restricted breed
                        dogs must be kept in certain conditions. Find out which
                        breeds are restricted, when a dog is considered
                      </p>
                    </aside>
                    <article>
                      <a href="services.html">
                        <img src={arrow} alt="" />
                      </a>
                    </article>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        
        }
        
      </section>
      { iframeSrc && <section>
        <div className="container">
          <div className="buttons">
            <ul>
              <li>
                <a role="button" className="Blue" onClick={navigatehome}>
                  <span>
                    <img src="images/left-arrow.png" alt="" />
                  </span>
                  Back
                </a>
                <a role="button" className="Yellow" onClick={handleButtonClick}>
                  <span>
                    <img src={keyboard} alt="" />
                  </span>
                  Keyboard
                </a>
                <a  role="button" className="Red" onClick={navigatehome}>
                  <span>
                    <img src={cancel} alt="" />
                  </span>
                  Cancel
                </a>
                <a role="button"className="Blue" onClick={navigatehome}>
                  <span>
                    <img src={home} alt="" />
                  </span>
                  Home
                </a>
              </li>
            </ul>
          </div>
        </div>
        {
              isKeyboardVisible && (
                <div>
                  <input
                    type="text"
                    value={input}
                    readOnly
                    onFocus={() => setKeyboardVisibility(true)}
                    onBlur={() => setKeyboardVisibility(false)}
                  />
                  <Keyboard
                    onChange={(input) => setInput(input)}
                    onKeyPress={onKeyPress}
                    layout={{
                      default: ['1 2 3 4 5 6 7 8 9 0 {bksp}', 'q w e r t y u i o p', 'a s d f g h j k l {enter}', '{shift} z x c v b n m {shift}', '.com @ {space}'],
                      shift: ['! @ # $ % ^ & * ( ) _ +', 'Q W E R T Y U I O P', 'A S D F G H J K L {enter}', '{shift} Z X C V B N M {shift}', '.com @ {space}'],
                    }}
                  />
                </div>
              )
            }
      </section>}
      <section>
        <div className="main-footer">
          <div className="container">
            <div className="footer">
              <span className="top-left" />
              <span className="top-right" />
              <span className="bottam-left" />
              <span className="bottam-right" />
              <div className="row">
                <div className="col-md-4 col-12">
                  <figure>
                    <img src={logo} alt="" />
                  </figure>
                </div>
                <div className="col-md-8 col-12">
                  <aside>
                    <h3>{fullData?.displayData?.[0]?.message}</h3>
                    <p>
                      {/* Mitchell Shire Council acknowledges the Taungurung and
                      Wurundjeri Woi Wurrung people as the Traditional Owners of
                      the lands and waterways in the area now known as Mitchell
                      Shire. We pay our respect to their rich cultures and to
                      Elders past, present and emerging, and other First Nations
                      people who live, work and play in the area. */}

                      {fullData?.displayData?.[0]?.description}
                    </p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
