import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Scanner from "../assets/images/scaner.png";
import leftarrow from "../assets/images/left-arrow.png";
import cancel from "../assets/images/cancel.png";
import keyboard from "../assets/images/keyboard.png";
import home from "../assets/images/home.png";
import { useNavigate } from "react-router-dom";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const Services = () => {
  const { state } = useLocation();
  const navigate = useNavigate()
  console.log("state----->", state);
  const [isKeyboardVisible, setKeyboardVisibility] = useState(false);
  const [input, setInput] = useState('');

  const handleButtonClick = () => {
    setKeyboardVisibility(!isKeyboardVisible);
   // window.scrollTo(0,10000)
  };
  const onKeyPress = (button) => {
    setInput((prevInput) => prevInput + button);
  };
  useEffect(()=>{
    window.scrollTo(0,1000)
  })


  return (
    <div>
      <section>
        <div className="service-header" />
        <div className="services-body">
          <div className="container">
            <div className="row">
              <div className="col-md-4 offset-md-2 col-sm-6">
                <div className="scanner-qr-code">
                  <h3>Scan QR Code of the Devices</h3>
                  <figure>
                    <span className="qr-line1"></span>
                    <span className="qr-line2"></span>
                    <span className="qr-line3"></span>
                    <span className="qr-line4"></span>
                    <img src={state?.qrcodeImage} alt="" />
                  </figure>
                  <p>
                    Please Scan QR code to complete the <br /> payment with your
                    portable device
                  </p>
                </div>
              </div>
              <div className="col-md-4 offset-md-2 col-sm-6">
                <article>
                  <figcaption>
                    <h4>
                      Scan QR code to complete the <br />
                      payment from your own device
                    </h4>
                    <ul>
                      <li>Open QR Scanner from your device</li>
                      <li>Scan QR mentioned left in the screen</li>
                      <li>Complete the payment</li>
                    </ul>
                  </figcaption>
                  <div className="services-img">
                    <img src={Scanner} alt="" />
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="buttons">
            <ul>
              <li>
                <a role="button" className="Blue" onClick={() => navigate('/', { replace: true })}  >
                  <span>
                    <img src={leftarrow} alt="" />
                  </span>
                  Back
                </a>
                <a role="button" className="Yellow" onClick={handleButtonClick}>
                  <span>
                    <img src={keyboard} alt="" />
                  </span>
                  Keyboard
                </a>
                <a role='button' className="Red" onClick={() => navigate('/', { replace: true })} >
                  <span>
                    <img src={cancel} alt="" />
                  </span>
                  Cancel
                </a>
                <a role="button" className="Blue" onClick={() => navigate('/')}>
                  <span>
                    <img src={home} alt="" />
                  </span>
                  Home
                </a>
              </li>
            </ul>

          </div>
        </div>
        {
              isKeyboardVisible && (
                <div>
                  <input
                    type="text"
                    value={input}
                    readOnly
                    onFocus={() => setKeyboardVisibility(true)}
                    onBlur={() => setKeyboardVisibility(false)}
                  />
                  <Keyboard
                    onChange={(input) => setInput(input)}
                    onKeyPress={onKeyPress}
                    layout={{
                      default: ['1 2 3 4 5 6 7 8 9 0 {bksp}', 'q w e r t y u i o p', 'a s d f g h j k l {enter}', '{shift} z x c v b n m {shift}', '.com @ {space}'],
                      shift: ['! @ # $ % ^ & * ( ) _ +', 'Q W E R T Y U I O P', 'A S D F G H J K L {enter}', '{shift} Z X C V B N M {shift}', '.com @ {space}'],
                    }}
                  />
                </div>
              )
            }
      </section>
    </div>
  );
};

export default Services;
