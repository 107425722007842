import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Services from "./components/Services";

function App() {
//   if (window.location.protocol == 'http:') {
          
//     window.location.href =
//         window.location.href.replace(
//                 'https://basscoastsscowes.com.au', 'https:');
// }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<Dashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/services" element={<Services />} />
          <Route />
          <Route />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
